import logo from './logo.svg';
import './App.css';
import Layout from './components/layout/Layout';
import AllRoutes from './allRoutes/AllRoutes';

function App() {
  return (
    <div className="wrapper">
      <AllRoutes />
    </div>
  );
}

export default App;

// If you have 10 field copy
// And once you complete the project in the customer copy download we need the last field copy date.
